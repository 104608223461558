import React, { useState } from 'react';
import uniqid from "uniqid";
import {
    Heading,
    Text,
    Box,
    Button,
    Divider
} from '@chakra-ui/react';

import { useApolloClient } from '@apollo/client';
import { SAVE_STRATEGY_FEEDBACK } from '../../../apollo/queries';

import Section from "../Section";
import FeedbackBox from "../../FeedbackBox";

import { MdFeedback, MdQuestionAnswer, MdCheckCircle, MdUndo, MdSave, MdCancel } from "react-icons/md";

const Feedback = (props) => {
    const { data } = props;

    const client = useApolloClient();

    const [isSaving, setIsSaving] = useState(false);

    const [feedback, setFeedback] = useState(data.feedback);

    const addFeedback = () => {
        const feedbackId = uniqid();
        const feedbackClone = feedback?.map(f=>f) || [];
        const feedbackItem = {
            id: feedbackId,
            feedback: "",
            response: "",
            actioned: false,
            complete: false,
        };

        feedbackClone.push(feedbackItem);
        setFeedback(feedbackClone);
    }

    const saveFeedback = (feedbackId, feedbackData) => {
		const tempFeedback = feedback?.map(feedbackItem => {

            if(feedbackItem.id === feedbackId) {
                console.log('got here');
				feedbackItem = { ...feedbackItem, ...feedbackData };
			}

			return feedbackItem;
		});

		setFeedback(tempFeedback);
    }

    const submitFeedback = () => {
        setIsSaving(true);
        const feedbackData = JSON.stringify(feedback || []);

        client.mutate({
            mutation: SAVE_STRATEGY_FEEDBACK,
            variables: {
                id: data.databaseId,
                feedback: feedbackData,
                bypass: true
            }
        }).then((res) => {
            setIsSaving(false);
        }).catch(e => {
            console.log(e);
            setIsSaving(false);
        });
    }
    
    return (
        <Section {...props} minWidth="500px">
            {feedback && feedback.length > 0 && feedback.map((item, itemIndex) => {
                if(item.actioned) {
                    return (
                        <Box mb="20px" key={item.id}>
                            <Heading size="md">{item.feedback}</Heading>
                            {item.response && <Text>{item.response}</Text>}
                            {!item.response && <Text color="red.500">No response yet...</Text>}
                        </Box>
                    );
                } else {
                    const isLast = itemIndex === feedback.length - 1;
                    return (
                        <Box key={item.id}>
                            <FeedbackBox {...item} isLast={isLast} save={saveFeedback}/>
                        </Box>
                    )
                }
            })}
            <Box textAlign={feedback && feedback.length > 0 ? "right" : "left"}>    
                <Button colorScheme="purple" size={feedback && feedback.length > 0 ? "sm" : "lg"} borderTopRadius={feedback && feedback.length > 0 ? 0 : "default"} onClick={addFeedback}>Add Feedback</Button>
            </Box>
            <Divider mt={10} mb={10}/>

            <Button size="lg" rightIcon={<MdQuestionAnswer />} colorScheme="green" isLoading={isSaving} onClick={submitFeedback}>Submit Feedback</Button>
        </Section>
    );
}

export default Feedback;
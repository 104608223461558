import React from "react";

import {
    Heading,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
  } from '@chakra-ui/react';

const ContactTable = (props) => {
    const { heading, details } = props;

    if(details) {
        return (
            <>
            <Heading size="sm">{heading}:</Heading>
            <Heading size="lg">{details.firstName} {details.lastName}</Heading>

            <Table variant="simple" size="sm" mt={4}>
            <Thead>
                <Tr>
                <Th>Contact details</Th>
                <Th></Th>
                </Tr>
            </Thead>
            <Tbody>
                <Tr>
                    <Td fontWeight="600">Email</Td>
                    <Td>{details.email}</Td>
                </Tr>
                <Tr>
                    <Td fontWeight="600">Phone</Td>
                    <Td>{details.phone}</Td>
                </Tr>
            </Tbody>
            </Table>
            </>
        );
    } else {
        return <></>;
    }
}

export default ContactTable;
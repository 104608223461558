import React, { useState } from 'react';
import {
    Center,
    Divider
} from '@chakra-ui/react';

import { ExternalLinkIcon } from '@chakra-ui/icons';

import Section from "../Section";
import PageFunctionality from "../PageFunctionality";

const FunctionalityScope = (props) => {
    const { data } = props;
    const { pages } = data;

    return (
        <Section {...props}>

            {pages && pages.map(page => {
                return <React.Fragment key={"page-functionality-" + page.id}>
                    <PageFunctionality page={page}/>
                    <Center py="50px"><Divider colorScheme="blue" borderColor="blue.500" borderWidth="2px" /></Center>
                </React.Fragment>
            })}

        </Section>
    );
}

export default FunctionalityScope;
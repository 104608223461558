import React from 'react';
import {
    Grid,
  } from '@chakra-ui/react';

import Section from "../Section";
import PageCard from "../PageCard";

const Pages = (props) => {
    const { data } = props;
    const { pages } = data;

    return (
        <Section {...props}>
            <Grid templateColumns="repeat(4, 1fr)" gap={6}>
                {pages && pages.map(page => <PageCard page={page} key={"page-preview-" + page.id}/>)}
            </Grid>
        </Section>
    );
}

export default Pages;
import React, { useState, useEffect } from 'react';
import {
    Box,
    Heading,
    List,
    ListItem,
    Link,
	LinkBox,
	LinkOverlay,
	ButtonGroup,
	Button,
	Center,
	Divider
} from '@chakra-ui/react';

import Scrollspy from 'react-scrollspy'


const Sidebar = (props) => {
	const { brand, sectionNav, title, stage } = props;

	const handleScroll = (id) => {
		document.querySelector('#' + id).scrollIntoView({
			behavior: 'smooth'
		});
	}

    return (
        <Box
        bg={brand + ".500"}
		pt={"calc(10vh + 20px)"}
		pb={20}
        w="20%"
        minW="400px"
        overflowY={"scroll"}
        top="0"
        position="sticky"
        h={"100vh"}
    >
        <Heading size="lg" color="#fff" mb="50px" pl={10} pr={10}>
        	{title}
        </Heading>

		<List>
			<Scrollspy
				items={ sectionNav.map(s=>s.id) }
				currentClassName="is-current"
				rootEl="#viewport-container"
				componentTag="div"
			>
				{sectionNav.map((header, index) => (
					<ListItem
						display="flex"
						alignItems="flex-start"
						color="#fff"
						fontSize="xl"
						borderBottomColor={brand + ".200"}
						borderBottomWidth={1}
						key={index + header.id}
						pl={10}
						pr={10}
						transition={"all 0.1s"}
						_hover={{
							bg: brand + ".300"
						}}
						sx={{
							"&.is-current": {
								bg: "white",
								color: brand + ".500"
							}
						}}
					>
						<LinkBox as="div" w="100%" h="100%" pt={3} pb={3}>
							<LinkOverlay
								href={`#${header.id}`}
								onClick={(e) => e.preventDefault() || handleScroll(header.id)}
							>
								{header.title}
							</LinkOverlay>
						</LinkBox>
					</ListItem>
				))}
			</Scrollspy>
		</List>
		{stage === "with_client" && <Center mt="100px">
			<ButtonGroup isAttached={true} size="md" variant="solid">
				<Button>Leave Feedback</Button>
				<Button colorScheme="green">Approve</Button>
			</ButtonGroup>
		</Center>}
    </Box>
    );
}

export default Sidebar;
import React from 'react';

import {
    Box,
    Image,
    Badge,
    Icon,
    Tooltip,
    HStack
  } from '@chakra-ui/react';

import { IoMdColorPalette } from 'react-icons/io';
import { BsCodeSlash } from 'react-icons/bs';

export default (props) => {
    const { page } = props;

    return (
        <Box
            maxW="100%"
            borderWidth="1px"
            borderRadius="lg"
            key={"page-item-" + page.id}
            cursor="pointer"
            transition="all 0.1s"
            _hover={{ borderColor: "blue.500" }}
        >
            <Box height="300px" overflow="hidden" bg="blue.50">
                {page.sections.map((section, sectionIndex) => {
                    return (
                        <Image
                            key={"section-image-preview-" + section.id}
                            src={section.image?.sourceUrl}
                            alt={section.image?.sourceUrl}
                            borderTopRadius={sectionIndex === 0 ? "lg" : 0}
                        />
                    );
                })}
            </Box>
            <Box p="6">
                <Box>
                
                {page.tags && page.tags.length > 0 && page.tags.map(tag => {
                    return (
                        <Badge borderRadius="full" px="2" colorScheme={tag.color} mr="1" key={"page-tag-" + tag.id}>
                            {tag.label}
                        </Badge>
                    );
                })}
                </Box>
                <Box
                mt="2"
                mb="2"
                fontWeight="semibold"
                as="h4"
                lineHeight="tight"
                isTruncated
                >
                {page.title}
                </Box>
                <Box>
                {page.formattedPrice}
                </Box>
                <HStack spacing="10px" fontSize="xs" fontWeight="600" mt="1">
                    <Tooltip hasArrow label="Design time">
                        <Box>
                            <Icon as={IoMdColorPalette} d="inline-block" fontSize="md" mb="4px" /> {page.designTime} hours
                        </Box>
                    </Tooltip>
                    <Tooltip hasArrow label="Development time">
                        <Box>
                            <Icon as={BsCodeSlash} d="inline-block" fontSize="md" mb="4px"  /> {page.developmentTime} hours
                        </Box>
                    </Tooltip>
                </HStack>                                
            </Box>
        </Box>
    );
}

import React, { useEffect, useState } from 'react'
import {
  Box,
  Flex,
} from '@chakra-ui/react';

import FullscreenLoader from "../components/FullscreenLoader";

import Sidebar from "../components/documentation/Sidebar";
import Header from "../components/documentation/Header";
import Footer from "../components/documentation/Footer";

import ClientInformation from "../components/documentation/sections/ClientInformation";
import StrategySummary from "../components/documentation/sections/StrategySummary";
import Pages from "../components/documentation/sections/Pages";
import FunctionalityScope from "../components/documentation/sections/FunctionalityScope";
import Quote from "../components/documentation/sections/Quote";
import Approval from "../components/documentation/sections/Approval";
import Feedback from "../components/documentation/sections/Feedback";

import { getFlatDataFromTree } from "react-sortable-tree";

import { navigate } from "gatsby"

import {
    SlideFade
} from "@chakra-ui/react";

import { useQuery } from '@apollo/client';
import { STRATEGY_BY_DBID } from '../apollo/queries.js';


const sectionNav = [
    {
        title: "Client Information",
        id: "client-information",
        image: "https://strategy.embarkagency.com.au/wp/wp-content/uploads/2021/08/searching-5.svg",
        component: ClientInformation
    },
    {
        title: "Strategy Summary",
        id: "strategy-summary",
        image: "https://strategy.embarkagency.com.au/wp/wp-content/uploads/2021/08/searching-5.svg",
        component: StrategySummary
    },
    {
        title: "Pages",
        id: "pages",
        image: "https://strategy.embarkagency.com.au/wp/wp-content/uploads/2021/08/searching-5.svg",
        component: Pages
    },
    {
        title: "Functionality Scope",
        id: "functionality-scope",
        image: "https://strategy.embarkagency.com.au/wp/wp-content/uploads/2021/08/searching-5.svg",
        component: FunctionalityScope
    },
    {
        title: "Quote",
        id: "quote",
        image: "https://strategy.embarkagency.com.au/wp/wp-content/uploads/2021/08/searching-5.svg",
        component: Quote
    },
    {
        title: "Feedback",
        id: "feedback",
        image: "https://strategy.embarkagency.com.au/wp/wp-content/uploads/2021/08/searching-5.svg",
        component: Feedback
    },
    {
        title: "Approval",
        id: "approval",
        image: "https://strategy.embarkagency.com.au/wp/wp-content/uploads/2021/08/searching-5.svg",
        component: Approval
    },
];


const DocumentationPage = () => {
    const [isShowing, setShow] = useState(false);

    useEffect(() => {
        return () => setShow(false)
    }, []);

    let strategyId;
    if(typeof document !== 'undefined') {
        const searchParams = new URL(document.location).searchParams;
        strategyId = searchParams.get('id');
    }

    if(strategyId) {
        const { loading, error, data } = useQuery(STRATEGY_BY_DBID, {
            variables: {
                id: parseInt(strategyId),
                bypass: true
            }
        });

        if(loading) {
            return <FullscreenLoader text="Loading Documentation"/>;
        }

        if(!loading && !isShowing) {
            setShow(true);
        }

        const strategyData = { ...data.strategyBy, ...data.strategyBy.strategyData };
        delete strategyData.strategyData;
        strategyData.databaseId = parseInt(strategyId);
        strategyData.sessionData = JSON.parse(strategyData.sessionData);
        strategyData.client = strategyData?.client?.client;
        strategyData.brand = "blue";

        strategyData.pages = getFlatDataFromTree({
            treeData: JSON.parse(JSON.stringify(strategyData.sessionData.pages)),
            getNodeKey: _=>_.node.id,
            ignoreCollapsed: false,
        }).map(page => {
            const node = page.node;
    
            node.designTime = 0;
            node.developmentTime = 0;
    
            node.sections.forEach(section => {
                node.designTime += (section.designTime || 0);
                node.developmentTime += (section.developmentTime || 0);
            })
            
            return node;
        });

        return (
			<SlideFade in={!loading && isShowing} transition={{ enter: {duration: 0.5}, exit: {duration: 0.5} }}>				
                <Box overflow={"scroll"} h="100vh" id="viewport-container" position="relative">
                    <Header brand={strategyData.brand}/>
                    <Flex gap={30} backgroundColor="#fff">
                        <Sidebar
                            sectionNav={sectionNav}
                            title={strategyData.title}
                            brand={strategyData.brand}
                            stage={strategyData.strategyStage}
                        />
                        <Box p={10} pt="100px" pb="100px">
                            {sectionNav.map(section => (
                                <section.component key={section.id} {...section} data={strategyData}/>
                            ))}
                        </Box>
                    </Flex>
                    <Footer brand={strategyData.brand}/>
                </Box>
			</SlideFade>
        );
    } else {
        if(typeof document !== 'undefined') {
            navigate('/');
        }
        return (<></>);
    }
}

export default DocumentationPage
import React from 'react';
import {
    Text,
    Heading,
    Grid,
    Box,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
} from '@chakra-ui/react';

import { getFlatDataFromTree } from "react-sortable-tree";

import { RiPagesLine } from 'react-icons/ri';

import Section from "../Section";

import hourlyRate from "../../../../hourlyRate";

const Quote = (props) => {
    const { data } = props;
    const { pages } = data;
    
    const extraData = data.sessionData?.steps?.Extras?.data?.data;
    const extraHours = (extraData?.hours || [])?.map(h => {
        h.hours = h.hours ? parseFloat(h.hours) : 0;
        return h;
    });

    const [hoursInitial, hoursTesting, hoursLaunch, hoursTraining] = extraHours;

    const allQuote = [
        {
            heading: "Full Responsive Design and XD Mockup",
            items: [
                {
                    for: "Full responsive mockup designed in Adobe XD for the following pages:",
                    description: false,
                    hours: JSON.parse(JSON.stringify(pages)).map(p => {
                        p.hours = p.designTime;
                        return p;
                    })
                }
            ]
        },
        {
            heading: "Website Development",
            items: [
                {...(hoursInitial ? hoursInitial : [])},
                {
                    for: "Website page development",
                    description: "Development of pages from mockup, including responsive testing,",
                    hours: JSON.parse(JSON.stringify(pages)).map(p => {
                        p.hours = p.developmentTime;
                        return p;
                    })    
                },
                {...(hoursTesting ? hoursTesting : [])},
                {...(hoursLaunch ? hoursLaunch : [])},
                {...(hoursTraining ? hoursTraining : [])}
            ]
        },
        {
            heading: "Additional Development",
            items: extraData && extraData.hours && extraData.hours.length ? extraData.hours?.slice(4) : []
        }
    ];

    let totalTime = 0;
    let blockIndex = 0;

    return (
        <Section {...props}>
            <Box w="100%">
                <Table variant="simple">
                    <TableCaption>Prices shown are excluding GST.</TableCaption>
                    {allQuote.map((block, blockI) => {
                        let subTotalTime = 0;
                        blockIndex += 1;
                        let blockItemIndex = 0;
                        return (
                            <React.Fragment key={"block-" + blockIndex}>
                            <Thead>
                                <Tr>
                                <Th colSpan="3" py="30px" fontSize="sm">{blockIndex}.0 {block.heading}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {block.items.map((blockItem, blockItemI) => {

                                    if(blockItem && Array.isArray(blockItem.hours)) {
                                        blockItemIndex += 1;
                                        return <React.Fragment key={"block-item-" + blockItemI}>
                                        <Tr>
                                            <Td>{blockIndex}.{blockItemIndex}</Td>
                                            <Td colSpan="2">
                                                {blockItem.for}
                                                {blockItem.description && <Text fontSize="xs">{blockItem.description}</Text>}
                                            </Td>
                                        </Tr>
                                        {blockItem.hours.map(page => {
                                            subTotalTime += page.hours || 0;
                                            return (
                                                <Tr key={"page-" + page.id}>
                                                    <Td></Td>
                                                    <Td fontSize="sm" pl="60px">{page.title}</Td>
                                                    <Td textAlign="right" fontSize="sm">${(page.hours * hourlyRate)}</Td>
                                                </Tr>
                                            )
                                        })}
                                        </React.Fragment>
                                    } else if(blockItem.heading || blockItem.for) {
                                        blockItemIndex += 1;
                                        subTotalTime += (blockItem?.hours) || 0
                                        return (
                                            <Tr key={"block-item-" + blockItemI}>
                                                <Td>{blockIndex}.{blockItemIndex}</Td>
                                                <Td>
                                                    {blockItem?.for}
                                                    {blockItem?.description && <Text fontSize="xs">{blockItem.description}</Text>}
                                                </Td>
                                                <Td textAlign="right">${blockItem?.hours * hourlyRate}</Td>
                                            </Tr>
                                        );
                                    } else {
                                        return <React.Fragment key={"block-item-" + blockItemI}></React.Fragment>
                                    }
                                })}

                                {block.items.length < 1 && <Tr colSpan="3">
                                    <Td p={3} colSpan={3} textAlign="center">No items here</Td>
                                </Tr>}

                                {block.items.length > 0 && <Tr bg="gray.100">
                                    <Td colSpan="2" textAlign="right" fontWeight="600">Subtotal</Td>
                                    {(() => {
                                        totalTime += subTotalTime;
                                    })()}
                                    <Td textAlign="right">${subTotalTime * hourlyRate}</Td>
                                </Tr>}
                            </Tbody>
                            </React.Fragment>
                        )
                    })}
                    <Tfoot bg="blue.500">
                        <Tr>
                        <Th colSpan="2" color="white" fontSize="xl" textAlign="right" py="30px" fontWeight="700">Total:</Th>
                        <Th color="white" fontSize="xl" colSpan="2" textAlign="right" py="30px" fontWeight="700">${totalTime * hourlyRate}</Th>
                        </Tr>
                    </Tfoot>
                </Table>
            </Box>
        </Section>
    );
}

export default Quote;
import React from 'react';
import {
    Text,
    Code,
    Textarea,
    Heading,
    Container,
    Box,
    Divider,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    Grid,
    Link
  } from '@chakra-ui/react';
  
import { ExternalLinkIcon } from '@chakra-ui/icons'

import Section from "../Section";
import ContactTable from "../ContactTable";

const ClientInformation = (props) => {
    const { client } = props.data;

    return (

        <Section w="100%" {...props}>

            <Box w="100%">

                {!client && <>
                    Client information not defined
                </>}

                {client && <>
                    <Heading size="sm">Business name:</Heading>
                    <Heading size="lg">{client.businessInformation?.businessName}</Heading>
                    {client.businessInformation?.websiteUrl && 
                    <Link href={client.businessInformation.websiteUrl} isExternal>
                        {client.businessInformation.websiteUrl} <ExternalLinkIcon mx="2px" />
                    </Link>}

                    <Divider m="30px 0"/>

                    <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                        <Box w="100%">
                            <ContactTable
                                heading="Primary contact"
                                details={client.primaryContact}
                            />
                        </Box>
                        <Box w="100%">
                            <ContactTable
                                heading="Accounts contact"
                                details={client.accountsContact}
                            />

                            {client.businessInformation?.alternateBillingBusinessName && (
                            <Table variant="simple" size="sm" mt={4}>
                                <TableCaption>Please bill to this business name.</TableCaption>
                                <Thead>
                                    <Tr>
                                        <Th>Alternate billing business name</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td>{client.businessInformation.alternateBillingBusinessName}</Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                            )}
                        </Box>
                        <br />
                    </Grid>

                    {client.businessInformation?.businessAddress && <Grid templateColumns="repeat(1, 1fr)" gap={6}>
                        <Box w="100%">
                            <Heading size="sm">Business address:</Heading>

                            <Table variant="simple" size="sm" mt={4}>
                            <Tbody>
                                <Tr>
                                <Td colSpan="1" fontWeight="600">Addres line 1</Td>
                                <Td colSpan="5">{client.businessInformation?.businessAddress.addressLine1}</Td>
                                </Tr>
                                {client.businessInformation?.businessAddress?.addressLine2 && <Tr>
                                <Td colSpan="1" fontWeight="600">Address line 2</Td>
                                <Td colSpan="5">{client.businessInformation?.businessAddress.addressLine2}</Td>
                                </Tr>}
                                <Tr>
                                <Td fontWeight="600">Suburb</Td>
                                <Td>{client.businessInformation?.businessAddress?.suburb}</Td>
                                <Td fontWeight="600">State</Td>
                                <Td>{client.businessInformation?.businessAddress?.state}</Td>
                                <Td fontWeight="600">Postcode</Td>
                                <Td>{client.businessInformation?.businessAddress?.postcode}</Td>
                                </Tr>
                            </Tbody>
                            </Table>
                        </Box>
                    </Grid>}                 
                </>}            

            </Box>

        </Section>
    );
}

export default ClientInformation;
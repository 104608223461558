import React from 'react';
import {
    Heading,
    Center,
  } from '@chakra-ui/react';

const Header = (props) => {
    const { brand } = props;

    return (
        <Center bg={brand + ".500"} minHeight="10vh">
            <Heading color="#FFF">Branded header</Heading>
        </Center>
    );
}

export default Header;
import React, { useState } from "react";

import {
    Text,
    Heading,
    Grid,
    GridItem,
    VStack,
    Box,
    StackDivider,
    UnorderedList,
    ListItem,
    Link,
    Image,
    Center,
    Icon
} from '@chakra-ui/react';

import { ExternalLinkIcon } from '@chakra-ui/icons';

import { AiOutlineFullscreen } from "react-icons/ai";

const PageFunctionality = (props) => {
    const { page } = props;

    const [highlighted, setHighlighted] = useState(false);

    return (
    <Grid templateColumns={"1fr 1fr"} gap={12}>
        
        {/* Each page contains a content column and a wirframe image column */}
        <GridItem>

            {/* Heading pulled from page name */}
            {/* TODO: Add tags and other page metadata here */}
            <Heading fontSize="xl" mb="4">{page.title}</Heading>

            <VStack
                divider={<StackDivider borderColor="gray.200" />}
                spacing={0}
                align="stretch"
                >
                {/* 
                    Start of section
                    Each Box compontent represents a different section 
                */}


                {page.sections.map((section, sectionIndex) => {
                    return (
                        <Box
                            key={"page-functionality-section-" + section.id}
                            onMouseEnter={() => setHighlighted(section.id)}
                            onMouseLeave={() => setHighlighted(false)}
                            cursor="pointer"
                            bg={highlighted === section.id ? "blue.50" : "white"}
                            py={6}
                        >
                            {/* Auto-generated incremental number + section name */}
                            <Heading fontSize="md" mb="2">{sectionIndex + 1}. {section.title}</Heading> 
                            {/* Section description */}
                            {section.description && <Text mb="4">{section.description}</Text>}
                            
                            {/* Features list */}
                            {section.features && section.features.filter(_=>_.name).length > 0 && (
                                <>
                                    <Heading fontSize="sm" mb="2">Features</Heading>
                                    <UnorderedList mb="4">
                                        {section.features.map(feature => feature.name && <ListItem key={"feature-"+feature.name}>{feature.name}</ListItem>)}
                                    </UnorderedList>
                                </>
                            )}
        
                            {/* Limitations list */}
                            {section.limitations && section.limitations.filter(_=>_.name).length > 0 && (
                                <>
                                    <Heading fontSize="sm" mb="2">Limitations</Heading>
                                    <UnorderedList mb="4">
                                        {section.limitations.map(limitation => limitation.name && <ListItem key={"limitation-"+limitation.name}>{limitation.name}</ListItem>)}
                                    </UnorderedList>
                                </>
                            )}
        
                            {/* Web examples list */}
                            {section.webExamples && section.webExamples.filter(_=>_.url).length > 0 && (
                                <>
                                    <Heading fontSize="sm" mb="2">Web examples</Heading>
                                    <UnorderedList mb="4">
                                        {section.webExamples.map(example => example.url && <ListItem key={"web-example-"+example.url}>
                                            <Link href={example.url} isExternal>
                                                {example.url} <ExternalLinkIcon mx="2px" />
                                            </Link>
                                        </ListItem>)}
                                    </UnorderedList>
                                </>
                            )}
                        </Box>
                    )

                })}

                {/* End of section */}

            </VStack>


        </GridItem>

        {/* Page wireframe column - sticky on scroll */}
        {/* TODO: See how this works when the wireframe is longer than screen height */}
        <GridItem>
            <Box
                h="fit-content"
                bg="blue.100"
                position="sticky"
                top="4"
                _hover={{
                    "& .fullscreen-hover": {
                        opacity: 1
                    }
                }}
            >
                <Box
                    cursor="pointer"
                    position="absolute"
                    top="0"
                    right="0"
                    bg="whiteAlpha.900"
                    w="100%"
                    h="100%"
                    opacity="0"
                    transition="all 0.2s"
                    className="fullscreen-hover"
                >
                    <Center w="100%" h="100%">
                        <Icon as={AiOutlineFullscreen} color="blue.500" fontSize="100px"/>
                    </Center>
                </Box>
                {page.sections.map(section => {
                    return (
                        <Box
                        bg="blue.500"
                            outline={"solid 3px blue"}
                            outlineColor={highlighted === section.id ? "blue.500" : "transparent"}
                            key={"section-image-"+section.id}
                            boxShadow="dark-lg"
                        >
                            <Image
                                // onMouseEnter={() => setHighlighted(section.id)}
                                // onMouseLeave={() => setHighlighted(false)}
                                cursor="pointer"
                                // transition="all 0.3s"
                                opacity={highlighted === section.id ? 0.8 : 1}
                                src={section.image?.sourceUrl}
                            />
                        </Box>
                    );
                })}
            </Box>
        </GridItem>
    </Grid>       
    );
}

export default PageFunctionality;
import React from 'react';
import {
    Box,
    Heading,
    Image,
    Text,
    HStack,
    Grid
  } from '@chakra-ui/react';

const Section = (props) => {
    return (
        <HStack
        mb="60px"
        spacing="40px"
        maxW={"1500px"}
        align="start"
        id={props.id}
        ref={props.ref}
        >

            <Grid templateColumns="200px 1fr" gap="30px">
                <Box w="100%">
                    <Image
                        src={props.image}
                        w="200px"
                        h="200px"
                    />
                </Box>
                <Box w="100%" width={props.width} maxWidth={props.maxWidth} minWidth={props.minWidth} w={props.w}>
                    <Heading mb="20px">{props.title}</Heading>
                    {props.children}
                </Box>
            </Grid>

        </HStack>
    );
}

export default Section;
import React from 'react';
import {
    Text,
  } from '@chakra-ui/react';

import Section from "../Section";

const Approval = (props) => {
    const { data } = props;
    
    return (
        <Section {...props}>
            <Text mb="20px">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
            fermentum semper varius. Morbi imperdiet accumsan egestas. Proin
            ullamcorper, neque at finibus commodo, lectus eros ullamcorper
            est, nec vulputate est ipsum id felis. Proin iaculis placerat
            gravida. Nulla aliquam rutrum ullamcorper. Aliquam rutrum orci ex,
            eu dictum ipsum porttitor vel. Duis volutpat tellus sed eleifend
            sodales. Aliquam nisl libero, rutrum ut tellus id, placerat
            posuere elit.
            </Text>
            <Text mb="20px">
            Etiam sodales tempor aliquam. Sed vulputate convallis ex a
            hendrerit. In ac mi dapibus, porta mi eget, viverra arcu. Nulla
            facilisi. Nunc eu venenatis sem. Vivamus sed massa nec enim dictum
            maximus molestie eu lorem. In condimentum lorem sed tellus rutrum
            dapibus. Praesent vel bibendum nulla, sed gravida enim. Quisque
            orci purus, feugiat et dolor ut, sollicitudin facilisis leo.
            Aliquam non nibh sit amet lorem feugiat pellentesque quis ac nunc.
            Donec erat tortor, condimentum tincidunt pharetra ac, ornare vel
            nulla.
            </Text>
            <Text mb="20px">
            Etiam sodales tempor aliquam. Sed vulputate convallis ex a
            hendrerit. In ac mi dapibus, porta mi eget, viverra arcu. Nulla
            facilisi. Nunc eu venenatis sem. Vivamus sed massa nec enim dictum
            maximus molestie eu lorem. In condimentum lorem sed tellus rutrum
            dapibus. Praesent vel bibendum nulla, sed gravida enim. Quisque
            orci purus, feugiat et dolor ut, sollicitudin facilisis leo.
            Aliquam non nibh sit amet lorem feugiat pellentesque quis ac nunc.
            Donec erat tortor, condimentum tincidunt pharetra ac, ornare vel
            nulla.
            </Text>
        </Section>
    );
}

export default Approval;